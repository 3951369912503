import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-cookie',
    templateUrl: './cookie.component.html',
    styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {
    showNessasaryCookies = true;
    showPerformanceCookies = false;
    performanceToggleEnabled  = false;

    constructor(private translate: TranslateService,
        private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document) { }

    ngOnInit(): void {
        //set translation language
        if(sessionStorage.getItem('translationLanguage')){
            this.translate.use(sessionStorage.getItem('translationLanguage'));
        }

        const cookieExists = this.readCookie('eys-cookie');
        if(cookieExists == 'true') {
            this.addAnalyticsScriptToDom();
            this.hideCookieMessage();
        }

        if(cookieExists == 'false') {
            this.hideCookieMessage();
        }

        if(cookieExists == null) {
            this.showCookieMessage();
        }
    }

    addAnalyticsScriptToDom() {
        const script = this._document.createElement('script');
        script.src = environment.analyticsUrl;
        this.renderer2.appendChild(this._document.head, script);
    }

    acceptAllCookies(): void {
        this.addAnalyticsScriptToDom();
        this._document.cookie = 'eys-cookie = true';		
        this.hideCookieMessage();
        this.reloadPage();
    }

    acceptNecessaryCookies(): void {
        this._document.cookie = 'eys-cookie = false';		
        this.hideCookieMessage();
        this.reloadPage();
    }

    reloadPage():void {
        location.reload();
      }

    hideCookieMessage(): void {
        this._document.getElementById('cookieMessageModal').classList.remove('visible');
        this._document.getElementById('cookieMessageModal').classList.add('hidden');
    }

    showCookieMessage(): void {
        this._document.getElementById('cookieMessageModal').classList.remove('hidden');
        this._document.getElementById('cookieMessageModal').classList.add('visible');
    }

    readCookie(cookieName) {
        const nameEQ = cookieName + '=';
        const ca = this._document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    saveCookiePreferences(): void {
        const performanceOptionIsChecked = (<HTMLInputElement>this._document.getElementById('performanceCookies')).checked;
        if(performanceOptionIsChecked) {
            this.acceptAllCookies();
        }
        else {
            this.acceptNecessaryCookies();
        }
    }

    collapseOrExpand() {
        const customizeSettings = this._document.getElementById('cookiePreferences');
        if(customizeSettings.classList.contains('collapse-in')) {
            customizeSettings.classList.remove('collapse-in');
            customizeSettings.classList.add('collapse-out');
        }
        else {
            customizeSettings.classList.remove('collapse-out');
            customizeSettings.classList.add('collapse-in');
        }
    }
}
