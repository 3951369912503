import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StuartModule, Main } from 'src/app/core/main/main';

@Component({
    selector: 'app-stuart',
    templateUrl: './stuart.component.html',
    styleUrls: ['./stuart.component.scss']
})
export class StuartComponent implements OnInit {
  @Input() stuartUrl: string;
  @Input() main: Main;

  public stuartTitle = '';
  public stuartText = '';
  public stuartButtonText = '';
  public stuartBgImage = '';

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
      //set translation language
      if(sessionStorage.getItem('translationLanguage')){
          this.translate.use(sessionStorage.getItem('translationLanguage'));
      }

      const stuartModule: StuartModule = this.main.StuartModule || {};

      if (stuartModule.StuartTitle && stuartModule.StuartTitle !== null && stuartModule.StuartTitle !== '') this.stuartTitle = stuartModule.StuartTitle;
      if (stuartModule.StuartText && stuartModule.StuartText !== null && stuartModule.StuartText !== '') this.stuartText = stuartModule.StuartText;
      if (stuartModule.StuartButtonText && stuartModule.StuartButtonText !== null && stuartModule.StuartButtonText !== '') this.stuartButtonText = stuartModule.StuartButtonText;
      if (stuartModule.StuartBackgroundImage && stuartModule.StuartBackgroundImage !== null && stuartModule.StuartBackgroundImage !== '') {
          this.stuartBgImage = stuartModule.StuartBackgroundImage;
      }
  }
}
