<div class="sdds-container no-padding stuart height-100">
    <div class="sdds-row stuart height-100 stuartModule-bkgd padding" [style.backgroundImage]="stuartBgImage !== ''? 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('+stuartBgImage+')': ''">
        <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 stuart">
            <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16">
                <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8 text-center">
                    <h3 *ngIf="stuartTitle === ''" class="text-center uppercase sdds-text-white hyphenate" data-cy="super-header">{{ 'eys.stuartModuleHeader' | translate }}</h3>
                    <h3 *ngIf="stuartTitle !== ''" class="text-center uppercase sdds-text-white hyphenate" data-cy="super-header">{{ stuartTitle }}</h3>
                </div>
                <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8">
                    <p *ngIf="stuartText === ''" class="sdds-text-white sdds-detail-01" data-cy="super-middle-text">{{ 'eys.stuartModuleBody' | translate }}</p>
                    <p *ngIf="stuartText !== ''" class="sdds-text-white sdds-detail-01" data-cy="super-middle-text">{{ stuartText }}</p>
                </div>
                <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8  text-center padding-bottom">
                    <a href="{{stuartUrl}}" target="_blank" id="stuartUrlLink" data-analytics="{'eventComponent':'stuart url','eventType':'link','eventLabel':'go to stuart url'}" data-cy="super-module-url">
                        <button class="sdds-btn stuart sdds-btn-default uppercase" data-cy="super-button">
                            <b *ngIf="stuartButtonText === ''">{{ 'eys.stuartModuleButton' | translate }}</b>
                            <b *ngIf="stuartButtonText !== ''">{{ stuartButtonText }}</b>
                        </button>
                    </a>
                </div>
                <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8">
                    <p class="sdds-text-white sdds-detail-01 stuartModuleDisclaimer">{{ 'eys.stuartModuleDisclaimer' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</div>