<div class="sdds-container no-padding marketingMaterial height-100">
    <div class="sdds-row marketingMaterial height-100 marketingMaterialModule-bkgd padding" [style.backgroundImage]="marketingMaterialBgImage !== ''? 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('+marketingMaterialBgImage+')': ''">
        <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 marketingMaterial">
            <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16">
                <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8 text-center">
                    <h3 *ngIf="marketingMaterialTitle === ''" class="text-center uppercase sdds-text-white hyphenate" data-cy="marketingMaterial-header">{{ 'eys.marketingMaterialModuleHeader' | translate }}</h3>
                    <h3 *ngIf="marketingMaterialTitle !== ''" class="text-center uppercase sdds-text-white hyphenate" data-cy="marketingMaterial-header">{{ marketingMaterialTitle }}</h3>
                </div>
                <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8 text-center">
                    <p *ngIf="marketingMaterialText === ''" class="text-center sdds-text-white sdds-detail-01" data-cy="marketingMaterial-text">{{ 'eys.marketingMaterialModuleBody' | translate }}</p>
                    <p *ngIf="marketingMaterialText !== ''" class="text-center sdds-text-white sdds-detail-01" data-cy="marketingMaterial-text">{{ marketingMaterialText }}</p>
                </div>
                <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8  text-center padding-bottom">
                    <a href="{{marketingMaterialUrl}}" target="_blank" id="marketingMaterialUrlLink" data-analytics="{'eventComponent':'marketingMaterialModule url','eventType':'link','eventLabel':'go to marketingMaterial url'}">
                        <button class="sdds-btn marketingMaterial sdds-btn-default uppercase" data-cy="marketingMaterial-button">
                            <b *ngIf="marketingMaterialButtonText === ''">{{ 'eys.marketingMaterialModuleButton' | translate }}</b>
                            <b *ngIf="marketingMaterialButtonText !== ''">{{ marketingMaterialButtonText }}</b>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>