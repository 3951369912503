import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2, Inject, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Main } from '../main/main';
import { AppSettings } from '../appSettings';



@Component({
  selector: 'app-mopinion',
  templateUrl: './mopinion.component.html',
  styleUrls: ['./mopinion.component.scss']
})
export class MopinionComponent implements OnInit {
  public scriptDataAsPerEnvironment: string;
  public scriptData = AppSettings.MOPINION_SCRIPT_DATA;
  public scriptLangIdentifier: string;
  public countryIso: string;
  @Input() public showFeedbackButton: boolean;
  @Input() main: Main;

  constructor(private translate: TranslateService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('translationLanguage')) {
      this.setTranslationLanguage();
    }
    this.addGlobalJsVarsForMopinionForms();
    this.addMopinionScriptToDom();
  }

  private setTranslationLanguage(){
    this.translate.use(sessionStorage.getItem('translationLanguage'));
  }

  addMopinionScriptToDom() {
    const script = this.renderer2.createElement('script');
    this.scriptDataAsPerEnvironment = this.scriptData.MOPINION_SCRIPT;
    if (this.showFeedbackButton && this.scriptDataAsPerEnvironment) {
      script.type = 'text/javascript';
      script.text = this.scriptDataAsPerEnvironment;
      this.renderer2.appendChild(this._document.body, script);
    }
  }

  addGlobalJsVarsForMopinionForms() {
    this.appendTranslationsScript();
    this.appendWebSiteDataScripts();
  }

  appendTranslationsScript() {
    this.scriptLangIdentifier = this.main.FallbackLanguage;
    const script = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.text = `var eysMopinionLanguage='${this.scriptLangIdentifier}'`;
    this.renderer2.appendChild(this._document.body, script);
  }

  appendWebSiteDataScripts() {
    this.countryIsoscript();
  }

  countryIsoscript() {
    this.countryIso = "" + this.main.CountryIso;
    const scriptForCountryIso = this.renderer2.createElement('script');
    scriptForCountryIso.type = 'text/javascript';
    scriptForCountryIso.text = `var countryIso='${this.countryIso}'`;
    this.renderer2.appendChild(this._document.body, scriptForCountryIso);
  }
}