<div id="cabBkgd" class="sdds-container-fluid cab-background height-100">
    <div class="sdds-row padding">
        <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8 text-center">
            <h3 class="text-center uppercase sdds-text-white hyphenate" data-cy="cab-module-header">{{ 'eys.cabDimensions' | translate }}</h3>
        </div>

        <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8 text-center">
            <p id="cabDimensionInfo" *ngIf="cabInSpecUrl.length > 0 && cabToCompareUrl.length <= 0" class="text-center sdds-text-white sdds-detail-01">{{ 'eys.cabDimensionInfo' | translate }}</p>
            <p id="miltiCabDimensionInfo" *ngIf="cabInSpecUrl.length > 0 && cabToCompareUrl.length > 0" class="text-center sdds-text-white sdds-detail-01">{{ 'eys.multiCabDimensionInfo' | translate }}</p>
        </div>
        
        <div id="cabDiv" class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8 text-center padding-bottom">
            <a id="cabMeasurementModal" (click)="openModel()" data-analytics="{'eventComponent':'cab measurement','eventType':'button','eventLabel':'open cab measurement modal'}">
                <button class="sdds-btn sdds-btn-default uppercase" id="cabMeasurementBtn">
                    <b>{{ 'eys.clickReadMore' | translate }}</b>
                </button>
            </a>
            <div class="sdds-modal-backdrop" [ngClass]="viewCabModel ? 'show' : 'hide'">
                <div class='sdds-modal sdds-modal-lg' id="cabMeasurement" data-cy="cab-measureMent-model">
                    <div class="sdds-modal-header custom-padding">
                        <div class="sdds-modal-headline float-left" id="cabMeasurementTitle">
                            <h5>{{ 'eys.cabDimensions' | translate }}</h5>
                        </div>
                        <span class="sdds-modal-btn" (click)="closeModel()" data-cy="cab-model-cross-button"></span>
                    </div>
                    <div class="sdds-modal-body no-padding" data-cy="cabMeasurement-model-body">
                        <div *ngIf="cabInSpecUrl.length > 0 && cabToCompareUrl.length <= 0"
                            class="remove-padding border-top padding">
                            <p class="no-margin text-align-left">{{ 'eys.oneCabInterest' | translate }}</p>
                            <p class="no-margin text-align-left">{{ 'eys.oneCabMeasurementsBelow' | translate }}</p>
                        </div>
                        <div *ngIf="cabInSpecUrl.length > 0 && cabToCompareUrl.length > 0"
                            class="remove-padding border-top padding">
                            <p class="no-margin text-align-left">{{ 'eys.twoCabInterest' | translate }}</p>
                            <p class="no-margin text-align-left">{{ 'eys.twoCabMeasurementsBelow' | translate }}</p>
                        </div>
                        <div *ngIf="cabInSpecUrl && cabToCompareUrl.length <= 0" id="scrollable" class="sdds-row row no-padding">
                            <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-4">
                                <b>
                                    <p *ngIf="cabInSpecUrl" class="sdds-text-blue-900">{{ cabDetails.CabInSpecs }}</p>
                                </b>
                                <div *ngFor="let imgUrl of cabInSpecUrl"
                                    class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-4">
                                    <img id="firstSideImg" width="80%" height="auto" src="{{imgUrl}}"
                                        class="hidden-sm hidden-md center-margin padding-bottom" />
                                    <img id="firstTopImg" width="100%" height="auto" src="{{imgUrl}}"
                                        class="hidden-lg hidden-xlg hidden-xxlg padding-bottom" />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="cabInSpecUrl.length > 0 && cabToCompareUrl.length > 0" id="scrollable"
                            class="sdds-row row no-padding">
                            <div class="sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-4 sdds-col-sm-4">
                                <b>
                                    <p *ngIf="cabInSpecUrl" class="sdds-text-blue-900">{{ cabDetails.CabInSpecs }}</p>
                                </b>
                                <div *ngFor="let imgUrl of cabInSpecUrl"
                                    class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-4 no-padding">
                                    <img id="firstSideImg" width="100%" height="auto" src="{{imgUrl}}" class="center-margin" />
                                </div>
                            </div>
                            <div class="sdds-col-xxlg-8 sdds-col-xlg-8 sdds-col-lg-8 sdds-col-md-4 sdds-col-sm-4">
                                <b>
                                    <p *ngIf="cabToCompareUrl" class="sdds-text-blue-900">{{ cabDetails.CabToCompare }}</p>
                                </b>
                                <div *ngFor="let imgUrl of cabToCompareUrl"
                                    class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-4 no-padding">
                                    <img id="firstTopImg" width="100%" height="auto" src="{{imgUrl}}" class="center-margin" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sdds-modal-actions float-right" data-cy="model-close-button">
                        <button (click)="closeModel()" id="closeCabModal" data-dismiss-modal
                            class="sdds-btn sdds-btn-secondary sdds-btn-sm">{{'eys.closeBtn' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>