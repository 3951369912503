<div *ngIf="showErrorMessage">
    <app-error></app-error>
</div>
<div *ngIf="!showErrorMessage && (displayViewYourTruck || displayCab)"
    class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 no-padding">
    <app-banner></app-banner>
</div>
<div *ngIf="!showErrorMessage" class="sdds-container min-container-height" data-cy="eys-container">
    <div class="sdds-row padding">
        <div *ngIf="displaySpinner"
            class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 no-padding text-center">
            <sdds-spinner size="lg"></sdds-spinner>
        </div>
        <!-- Top section -->
        <div *ngIf="displayQuotationModule"
            class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 no-padding">
            <div class="sdds-row no-padding float-right">
                <app-quotation [main]="main"></app-quotation>
                <app-documents *ngIf="displayAdditionalDocs" [main]="main" class="doc-btn-container"></app-documents>
            </div>
        </div>
        <div *ngIf="displayAdditionalDocs && !displayQuotationModule"
            class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 no-padding">
            <app-documents [main]="main"></app-documents>
        </div>
        <!-- Center-Top section -->
        <div *ngIf="displayViewYourTruck && !sectionTopRight && !showErrorMessage"
            class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 sdds-background-white border">
            <app-view-your-truck [main]="main"></app-view-your-truck>
        </div>
        <!-- Page-Expired-Message section -->
        <div *ngIf="eysPageExpiredMessage && !sectionTopRight && !showErrorMessage"
            class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 margin-top margin">
            <div class="sdds-message sdds-message__type-informative sdds-message__icon-active">
                <svg class="sdds-message-icon sdds-message-icon--informative" width="20" height="20" viewBox="0 0 20 20"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10.008 2.50244C5.85715 2.50244 2.49219 5.8674 2.49219 10.0183C2.49219 14.1692 5.85715 17.5341 10.008 17.5341C14.1589 17.5341 17.5239 14.1692 17.5239 10.0183C17.5239 5.8674 14.1589 2.50244 10.008 2.50244ZM1.24219 10.0183C1.24219 5.17705 5.16679 1.25244 10.008 1.25244C14.8493 1.25244 18.7739 5.17705 18.7739 10.0183C18.7739 14.8595 14.8493 18.7841 10.008 18.7841C5.16679 18.7841 1.24219 14.8595 1.24219 10.0183Z"
                        fill="#2B70D3"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10.0156 8.82889C10.3608 8.82889 10.6406 9.10871 10.6406 9.45389L10.6406 12.9635C10.6406 13.3087 10.3608 13.5885 10.0156 13.5885C9.67045 13.5885 9.39063 13.3087 9.39063 12.9635L9.39063 9.45389C9.39063 9.10871 9.67045 8.82889 10.0156 8.82889Z"
                        fill="#2B70D3"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10.0156 6.44809C10.3608 6.44809 10.6406 6.72791 10.6406 7.07309L10.6406 7.20878C10.6406 7.55396 10.3608 7.83378 10.0156 7.83378C9.67045 7.83378 9.39062 7.55396 9.39062 7.20878L9.39062 7.07309C9.39062 6.72791 9.67045 6.44809 10.0156 6.44809Z"
                        fill="#2B70D3"></path>
                </svg>
                <h4 class="sdds-message-single sdds-message-single-custome">
                    {{'eys.eysPageExpiredMsg'|translate}}
                </h4>
            </div>
        </div>
        <!-- Bottom section -->
        <div *ngIf="sectionBottom && firstModuleSlot && !showErrorMessage" id="sectionBottomContainer1"
            class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 no-padding border">
            <ng-container *ngTemplateOutlet="FirstModuleTemplate"></ng-container>
        </div>
        <div *ngIf="sectionBottom && secondModuleSlot && !showErrorMessage" id="sectionBottomContainer2"
            class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 no-padding border">
            <ng-container *ngTemplateOutlet="SecondModuleTemplate"></ng-container>
        </div>
        <div *ngIf="sectionBottom && thirdModuleSlot && main.CountryIso === 'PL' && !showErrorMessage" id="sectionBottomContainer3"
            class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 no-padding border">
            <ng-container *ngTemplateOutlet="ThirdModuleTemplate"></ng-container>
        </div>
        <!-- Cookie section -->
        <div *ngIf="showCookieMessage" id="cookieContainer"
            class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16">
            <app-cookie></app-cookie>
        </div>
    </div>
</div>
<!-- Footer section -->
<div *ngIf="!showErrorMessage && (displayViewYourTruck || displayCab)"
    class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 no-padding">
    <app-footer [main]="main"></app-footer>
</div>
<!-- User feedback Mopinion section -->
<div id="surveyContent" *ngIf="!showErrorMessage && (displayViewYourTruck || displayCab)">
    <app-mopinion [main]="main" [showFeedbackButton]="showCookieMessage"></app-mopinion>
</div>

<ng-template #FirstModuleTemplate>
    <ng-container *ngFor="let module of modules">
        <ng-container *ngIf="module.Key === 'cab' && module.Order === 1; then cabDataTemplate;"></ng-container>
        <ng-container
            *ngIf="module.Key === 'campaign' && module.Order === 1 && (main.CampaignModule && main.CampaignModule.CampaignUrl) !== ''; then campaignModuleDataTemplate;">
        </ng-container>
        <ng-container
            *ngIf="module.Key === 'super' && module.Order === 1 && (main.StuartModule && main.StuartModule.StuartUrl) !== ''; then stuartModuleDataTemplate;">
        </ng-container>
        <ng-container
            *ngIf="module.Key === 'marketing material - vehicle & services' && module.Order === 1 && (main.MarketingMaterialModule && main.MarketingMaterialModule.MarketingMaterialUrl) !== ''; then marketingMaterialModuleDataTemplate;">
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #SecondModuleTemplate>
    <ng-container *ngFor="let module of modules">
        <ng-container *ngIf="module.Key === 'cab'&& module.Order === 2; then cabDataTemplate;"></ng-container>
        <ng-container
            *ngIf="module.Key === 'campaign' && module.Order === 2 && (main.CampaignModule && main.CampaignModule.CampaignUrl) !== ''; then campaignModuleDataTemplate;">
        </ng-container>
        <ng-container
            *ngIf="module.Key === 'super' && module.Order === 2 && (main.StuartModule && main.StuartModule.StuartUrl) !== ''; then stuartModuleDataTemplate;">
        </ng-container>
        <ng-container
            *ngIf="module.Key === 'marketing material - vehicle & services' && module.Order === 2 && (main.MarketingMaterialModule && main.MarketingMaterialModule.MarketingMaterialUrl) !== ''; then marketingMaterialModuleDataTemplate;">
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #ThirdModuleTemplate>
    <ng-container *ngFor="let module of modules">
        <ng-container *ngIf="module.Key === 'cab'&& module.Order === 3; then cabDataTemplate;"></ng-container>
        <ng-container
            *ngIf="module.Key === 'campaign' && module.Order === 3 && (main.CampaignModule && main.CampaignModule.CampaignUrl) !== ''; then campaignModuleDataTemplate;">
        </ng-container>
        <ng-container
            *ngIf="module.Key === 'super' && module.Order === 3 && (main.StuartModule && main.StuartModule.StuartUrl) !== ''; then stuartModuleDataTemplate;">
        </ng-container>
        <ng-container
            *ngIf="module.Key === 'marketing material - vehicle & services' && module.Order === 3 && (main.MarketingMaterialModule && main.MarketingMaterialModule.MarketingMaterialUrl) !== ''; then marketingMaterialModuleDataTemplate;">
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #sectionBottomTemplate>
    <ng-container *ngIf="sectionBottom === 'cab'; then cabDataTemplate;"></ng-container>
    <ng-container
        *ngIf="sectionBottom === 'campaign' && main.CampaignModule && main.CampaignModule.CampaignUrl !== ''; then campaignModuleDataTemplate;">
    </ng-container>
    <ng-container
        *ngIf="sectionBottom === 'super' && main.StuartModule && main.StuartModule.StuartUrl !== ''; then stuartModuleDataTemplate;">
    </ng-container>
    <ng-container
            *ngIf="sectionBottom === 'marketing material - vehicle & services' && main.MarketingMaterialModule && main.MarketingMaterialModule.MarketingMaterialUrl !== ''; then marketingMaterialModuleDataTemplate;">
    </ng-container>
</ng-template>

<ng-template #cabDataTemplate>
    <app-cab [cabDetails]="cabDetails"></app-cab>
</ng-template>

<ng-template #campaignModuleDataTemplate>
    <app-campaign [campaignUrl]="main.CampaignModule.CampaignUrl" [main]="main"></app-campaign>
</ng-template>

<ng-template #stuartModuleDataTemplate>
    <app-stuart [stuartUrl]="main.StuartModule.StuartUrl" [main]="main"></app-stuart>
</ng-template>

<ng-template #marketingMaterialModuleDataTemplate>
    <app-marketing-material [marketingMaterialUrl]="main.MarketingMaterialModule.MarketingMaterialUrl" [main]="main"></app-marketing-material>
</ng-template>