<div class="sdds-container no-padding campaign height-100">
    <div class="sdds-row campaign height-100 campaignModule-bkgd padding" [style.backgroundImage]="campaignBgImage !== ''? 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('+campaignBgImage+')': ''">
        <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16 campaign">
            <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-16 sdds-col-sm-16">
                <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8 text-center">
                    <h3 *ngIf="campaignTitle === ''" class="text-center uppercase sdds-text-white hyphenate" data-cy="campaign-header">{{ 'eys.campaignModuleHeader' | translate }}</h3>
                    <h3 *ngIf="campaignTitle !== ''" class="text-center uppercase sdds-text-white hyphenate" data-cy="campaign-header">{{ campaignTitle }}</h3>
                </div>
                <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8 text-center">
                    <p *ngIf="campaignText === ''" class="text-center sdds-text-white sdds-detail-01" data-cy="campaign-text">{{ 'eys.campaignModuleBody' | translate }}</p>
                    <p *ngIf="campaignText !== ''" class="text-center sdds-text-white sdds-detail-01" data-cy="campaign-text">{{ campaignText }}</p>
                </div>
                <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8  text-center padding-bottom">
                    <a href="{{campaignUrl}}" target="_blank" id="campaignUrlLink" data-analytics="{'eventComponent':'campaign url','eventType':'link','eventLabel':'go to campaign url'}">
                        <button class="sdds-btn campaign sdds-btn-default uppercase" data-cy="campaign-button">
                            <b *ngIf="campaignButtonText === ''">{{ 'eys.campaignModuleButton' | translate }}</b>
                            <b *ngIf="campaignButtonText !== ''">{{ campaignButtonText }}</b>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>