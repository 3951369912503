import { Component} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public showLandingPage = false;
    constructor(private translate: TranslateService) {

        //set translation language
        if (sessionStorage.getItem('translationLanguage')) {
            this.translate.use(sessionStorage.getItem('translationLanguage'));
        }
        else {
            sessionStorage.setItem('translationLanguage', 'en-GB');
            this.translate.use(sessionStorage.getItem('translationLanguage'));
        }

        // Check if default page
        const pageUrl = window.location.pathname;
        if (pageUrl.toString() == '/') {
            this.showLandingPage = true;
        } else {
            this.showLandingPage = false;
        }
    }
}
