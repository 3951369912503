import { environment } from 'src/environments/environment';

export class AppSettings {
    public static readonly BASE_SERVICE_URL = environment.apiUrl;
    public static readonly BASE_Email_URL = environment.eysEmailApi;
    public static readonly ASSETS_URL = environment.assetsUrl;
    public static readonly MOPINION_SCRIPT_DATA = environment.mopinionScriptData;
    public static readonly TRANSLATION_SERVICE_URL = environment.translationServiceUrl;
    // Errors
    public static readonly BAD_REQUEST = 'Bad Request. ';
    public static readonly SERVER_ERROR = 'Server Error. ';
    public static readonly UNEXPECTED_ERROR = 'Unexpected Error. ';
  
    public static readonly NOT_FOUND = 'not found';
    public static readonly ALREADY_EXIST = 'already exist';
    public static readonly IN_USE = 'in use';
    public static readonly NOT_IN_AD_GROUP = 'USER_NOT_IN_AD_GROUP';
    public static readonly COMBINED_NAME = 'Combined Name';
}