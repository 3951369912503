import { Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieComponent } from '../cookie/cookie.component';
import { Main } from '../main/main';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    @Input() main: Main;
    public showPrivacySettings = false;
    public saleRepName: string;
    public saleRepEmail: string;
    public displayLandingPage= false;
    public contactImageUrl = 'https://exploreyourscania-assets-eu-west-1-dev.s3.eu-west-1.amazonaws.com/ui-assets/contact_info.png';
    public currentYear = new Date().getFullYear();

    get additionalFooterText(): string {
        const key = 'eys.additionalFooterText'
        const text = this.translate.instant(key);
        if (text === key) return '';
        return text;
    }

    constructor(private translate: TranslateService, private cookieComponent: CookieComponent) { }

    ngOnInit(): void {
        //set translation language
        if (sessionStorage.getItem('translationLanguage')) {
            this.translate.use(sessionStorage.getItem('translationLanguage'));
        }

        // Check if default page
        const pageUrl = window.location.pathname;
        if(pageUrl.toString() == '/') {
            this.showPrivacySettings = false;
            this.displayLandingPage = true;
        }
        else {
            this.showPrivacySettings = true;
            this.displayLandingPage = false;
            this.saleRepName = this.main.SalesRepFullName;
            this.saleRepEmail = this.main.SalesRepEmail;
        }
    }

    openCookieModal(): void {
        this.cookieComponent.showCookieMessage();
    }
}
