import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CampaignModule, Main } from 'src/app/core/main/main';

@Component({
    selector: 'app-campaign',
    templateUrl: './campaign.component.html',
    styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {
  @Input() campaignUrl: string;
  @Input() main: Main;

  public campaignTitle = '';
  public campaignText = '';
  public campaignButtonText = '';
  public campaignBgImage = '';

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
      //set translation language
      if(sessionStorage.getItem('translationLanguage')){
          this.translate.use(sessionStorage.getItem('translationLanguage'));
      }

      const campaignModule: CampaignModule = this.main.CampaignModule || {};

      if (campaignModule.CampaignTitle && campaignModule.CampaignTitle !== null && campaignModule.CampaignTitle !== '') this.campaignTitle = campaignModule.CampaignTitle;
      if (campaignModule.CampaignText && campaignModule.CampaignText !== null && campaignModule.CampaignText !== '') this.campaignText = campaignModule.CampaignText;
      if (campaignModule.CampaignButtonText && campaignModule.CampaignButtonText !== null && campaignModule.CampaignButtonText !== '') this.campaignButtonText = campaignModule.CampaignButtonText;
      if (campaignModule.CampaignBackgroundImage && campaignModule.CampaignBackgroundImage !== null && campaignModule.CampaignBackgroundImage !== '') {
          this.campaignBgImage = campaignModule.CampaignBackgroundImage;
      }
  }
}
