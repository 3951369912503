import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MarketingMaterialModule, Main } from 'src/app/core/main/main';

@Component({
  selector: 'app-marketing-material',
  templateUrl: './marketing-material.component.html',
  styleUrls: ['./marketing-material.component.scss']
})
export class MarketingMaterialComponent implements OnInit {
  @Input() marketingMaterialUrl: string;
  @Input() main: Main;

  public marketingMaterialTitle = '';
  public marketingMaterialText = '';
  public marketingMaterialButtonText = '';
  public marketingMaterialBgImage = '';

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
      //set translation language
      if(sessionStorage.getItem('translationLanguage')){
          this.translate.use(sessionStorage.getItem('translationLanguage'));
      }

      const marketingMaterialModule: MarketingMaterialModule = this.main.MarketingMaterialModule || {};

      if (marketingMaterialModule.MarketingMaterialTitle && marketingMaterialModule.MarketingMaterialTitle !== null && marketingMaterialModule.MarketingMaterialTitle !== '') this.marketingMaterialTitle = marketingMaterialModule.MarketingMaterialTitle;
      if (marketingMaterialModule.MarketingMaterialText && marketingMaterialModule.MarketingMaterialText !== null && marketingMaterialModule.MarketingMaterialText !== '') this.marketingMaterialText = marketingMaterialModule.MarketingMaterialText;
      if (marketingMaterialModule.MarketingMaterialButtonText && marketingMaterialModule.MarketingMaterialButtonText !== null && marketingMaterialModule.MarketingMaterialButtonText !== '') this.marketingMaterialButtonText = marketingMaterialModule.MarketingMaterialButtonText;
      if (marketingMaterialModule.MarketingMaterialBackgroundImage && marketingMaterialModule.MarketingMaterialBackgroundImage !== null && marketingMaterialModule.MarketingMaterialBackgroundImage !== '') {
          this.marketingMaterialBgImage = marketingMaterialModule.MarketingMaterialBackgroundImage;
      }
  }
}
