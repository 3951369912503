import { Component, Input, OnInit } from '@angular/core';
import { EysSendEmailResponse, Main, QuotationDocs } from 'src/app/core/main/main';
import { TranslateService } from '@ngx-translate/core';
import { MainService } from 'src/app/core/main/main.service';


@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {

  @Input() main: Main;
  public quotationDocs: QuotationDocs[];
  public quotationValidity: number;
  public docLinkExpired: boolean;
  public saleRepName: string;
  public saleRepEmail: string;
  public token: string;
  public isLoading = true;
  public showEysEmailSuccess: boolean;
  public showEysEmailFailure: boolean;
  public quotationRenewalRequestSent:boolean; 

  constructor(private translate: TranslateService , private mainService: MainService) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('translationLanguage')) {
      this.translate.use(sessionStorage.getItem('translationLanguage'));
    }
    this.quotationDocs = (this.main.QuotationDocs && this.main.QuotationDocs.length ? this.main.QuotationDocs : null);
    this.quotationValidity = this.main.QuotationValidity;
    this.saleRepName = this.main.SalesRepFullName;
    this.saleRepEmail = this.main.SalesRepEmail;
    const params = new URLSearchParams(document.location.search);
    this.token = params.get("token");
    this.quotationDocsLinkStatus();
  }

  public quotationDocsLinkStatus() {
    if (this.quotationValidity < 0 && this.quotationDocs === null) {
      this.docLinkExpired = true;
      if (this.main.QuotationRenewalRequestSent === true) {
        this.quotationRenewalRequestSent = true;
      }
      else {
        this.quotationRenewalRequestSent === false;
      }
    }
    else {
      this.docLinkExpired = false;
    } 
  }

  public sendMailTOSaleRep() {
    this.isLoading = true;
    let pagePathId: string;
    const pageUrl = window.location.pathname;
    const preview = 'preview';
    if (pageUrl.toString().includes(preview)) {
      pagePathId = pageUrl.slice(pageUrl.indexOf(preview) + (preview.length + 1));
    } else {
      pagePathId = pageUrl.substring(1);
    }
    this.mainService.sendEysEmail(pagePathId, this.token).subscribe(
      (resp:EysSendEmailResponse) => {
        if (resp.Message === 'Success') {
          this.showEysEmailSuccess = true;
          this.quotationRenewalRequestSent = true;
          this.isLoading = false;
        }
        else {
          this.showEysEmailFailure = true;
          this.quotationRenewalRequestSent = false;
          this.isLoading = false;
        }
      },
      () => {
        this.isLoading = false;
        this.showEysEmailFailure= true;
        this.quotationRenewalRequestSent = false;
      }
    );
  }

}
