import { MopinionScriptDataProd } from "src/app/shared/mopinion-script-constants";

export const environment = {
    production: true,
    apiUrl: 'https://eys-api.digitaljourney.prod.aws.scania.com/eys',
    eysEmailApi:'https://eys-api.digitaljourney.prod.aws.scania.com/eys-quotation-renew',
    analyticsUrl: '//assets.adobedtm.com/03cc06ac496b/ebcad0dbecd0/launch-9756fcdf1fe6.min.js',
    assetsUrl: 'https://exploreyourscania-assets-eu-west-1-prod.s3-eu-west-1.amazonaws.com/ui-assets',
    mopinionScriptData: MopinionScriptDataProd,
    translationServiceUrl: 'https://api-translations.transformers.prod.aws.scania.com/translations'
};
