import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdditionalDocs, Main } from 'src/app/core/main/main';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  @Input() main: Main;
  public additionalDocs: AdditionalDocs[];

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('translationLanguage')) {
      this.translate.use(sessionStorage.getItem('translationLanguage'));
    }
    this.additionalDocs = (this.main.AdditionalDocs && this.main.AdditionalDocs.length ? this.main.AdditionalDocs : null)

  }

}
