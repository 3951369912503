<div class="sdds-container no-padding">
    <div
        class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8 col-small text-rigth no-padding">
        <a (click)="''" id="multipleDocsModel"
            data-analytics="{'eventComponent':'additional documents','eventType':'link','eventLabel':'go to additional documents url'}"
            data-cy="docs-model-link">
            <button class="sdds-btn sdds-btn-secondary sdds-btn-sm custom-sm-button border btn-font-sm"
                id="document-button">
                <span *ngIf="additionalDocs.length === 1; else multipleDocs"
                    class="document-button-text">{{'eys.additionalDocBtnText' |translate}}</span>
                <ng-template #multipleDocs>
                    <span class="document-button-text">{{'eys.additionalDocsBtnText' |translate}}</span>
                </ng-template>
                <span class='sdds-btn-icon-secondary document-button-icon'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M3.75 2.5C3.75 1.80964 4.30964 1.25 5 1.25H9.74113C10.0726 1.25 10.3906 1.38169 10.625 1.61612L15.8839 6.875C16.1183 7.10944 16.25 7.42737 16.25 7.75887V17.5C16.25 18.1904 15.6904 18.75 15 18.75H5C4.30964 18.75 3.75 18.1904 3.75 17.5V2.5ZM9.375 2.5H5V17.5H15V8.125H10.625C9.93463 8.125 9.375 7.56537 9.375 6.875V2.5ZM10.625 3.38388V6.875H14.1161L10.625 3.38388Z"
                            fill="#0D0F13" />
                    </svg>
                </span>
            </button>
        </a>
    </div>
    <sdds-modal size="sm" selector="#multipleDocsModel" data-cy="docs-model" prevent>
        <h5 class="padding-left" slot="sdds-modal-headline" data-cy="doc-model-header"
            *ngIf="additionalDocs.length === 1; else documents">
            {{'eys.additionalDocBtnText'|translate}}
        </h5>
        <ng-template #documents>
            <h5 class="padding-left" slot="sdds-modal-headline" data-cy="doc-model-header" #documents>
                {{'eys.additionalDocsBtnText'|translate}}
            </h5>
        </ng-template>
        <div slot="sdds-modal-body" class="remove-right-padding">
            <div class="no-padding border-bottom model-document-row" *ngFor="let doc of additionalDocs; let i = index">
                <a href="{{doc.FileLink}}" target="_blank" id="documentUrlLink"
                    class="sdds-btn sdds-btn-ghost sdds-btn-sm sdds-row">
                    <span class='sdds-btn-icon-secondary document-icon'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M3.75 2.5C3.75 1.80964 4.30964 1.25 5 1.25H9.74113C10.0726 1.25 10.3906 1.38169 10.625 1.61612L15.8839 6.875C16.1183 7.10944 16.25 7.42737 16.25 7.75887V17.5C16.25 18.1904 15.6904 18.75 15 18.75H5C4.30964 18.75 3.75 18.1904 3.75 17.5V2.5ZM9.375 2.5H5V17.5H15V8.125H10.625C9.93463 8.125 9.375 7.56537 9.375 6.875V2.5ZM10.625 3.38388V6.875H14.1161L10.625 3.38388Z"
                                fill="#2B70D3" />
                        </svg>
                    </span> <span class='document-name'>{{doc.FileName}}</span>
                </a>
            </div>
        </div>
        <button slot="sdds-modal-actions" data-dismiss-modal onclick="'close'"
            class="sdds-btn sdds-btn-secondary sdds-btn-sm custom-model-button" data-cy="quotation-model-close-button">
            {{'eys.closeBtn'|translate}}
        </button>
    </sdds-modal>
</div>