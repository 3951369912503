<sdds-modal size="md" id="cookieMessageModal" prevent class="hidden" selector="anys">
    <div slot="sdds-modal-body" class="modal-padding border-bottom scrollable">
        <h5 id="cookieModalTitle" class="margin-top-none">{{ 'eys.cookiePrivacySettingsBtn' | translate }}</h5>
        <p id="cookieText">{{ 'eys.cookieText' | translate }}</p>

        <a id="customizeSettings" (click)="collapseOrExpand()">
            {{ 'eys.customizeSettings' | translate }}
            <sdds-icon name="scania-angle-down"></sdds-icon>
        </a>

        <div class="clear-both"></div>

        <div id="cookiePreferences" class="collapse-out margin-top">
            <div class="sdds-radio-button">
                <div class="sdds-checkbox-item">
                    <input class="sdds-form-input" type="checkbox" name="necessaryCookie" id="necessaryCookies" checked="checked" disabled>
                     <label class="sdds-form-label" for="necessaryCookies">
                        {{ 'eys.necessaryCookie' | translate }}
                     </label>
                </div>
                <p>{{ 'eys.necessaryCookieText' | translate }}</p>
            </div>

            <div class="sdds-radio-button">
                <div class="sdds-checkbox-item">
                    <input class="sdds-form-input" type="checkbox" name="performanceCookie" id="performanceCookies" checked="checked">
                     <label class="sdds-form-label" for="performanceCookies">
                        {{ 'eys.performanceCookie' | translate }}
                     </label>
                </div>
                <p>{{ 'eys.performanceCookieText' | translate }}</p>
            </div>

            <button (click)="saveCookiePreferences()" class="sdds-btn sdds-btn-secondary sdds-btn-fullbleed">{{ 'eys.saveSettinsBtn' | translate }}</button>
        </div>
    </div>
    <button slot="sdds-modal-actions" id="acceptAllBtn" (click)="acceptAllCookies()" class="sdds-btn sdds-btn-primary float-right margin-top">{{ 'eys.cookieAcceptAllBtn' | translate }}</button>
</sdds-modal>