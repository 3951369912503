import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
//import { catchError } from 'rxjs/operators';

import { AppSettings } from '../appSettings';
import { Main } from './main';
import { EysSendEmailResponse } from './main';

@Injectable({
    providedIn: 'root'
})
export class MainService {

    constructor(private http: HttpClient) { }

    public getSpecificationDetails(id: string, token: string): Observable<Main> {
      let apiUrl = `${AppSettings.BASE_SERVICE_URL}/${id}`;
      if (token && token !== "") apiUrl = `${apiUrl}?token=${token}`;
      return this.http.get<Main>(apiUrl)
        .pipe(
        // catchError(this.handleError<Main>(`getById id=${id}`))
      );
	}

  public sendEysEmail(id: string, token: string): Observable<EysSendEmailResponse> {
    const emailApiurl = `${AppSettings.BASE_Email_URL}/${id}?token=${token}`;
    return this.http.post<EysSendEmailResponse>(emailApiurl, null);
  }

    //private handleError<T>(operation = 'operation', result?: T) {
    // return this.alertService.handleError(AppSettings.BASE_SERVICE_URL, operation, result);
    //}
}
