<div class="sdds-header">
	<span class="scania-text-symbol"></span>

	<span id="application-name" *ngIf="!showCompnayName" class="uppercase application-name is-landing-page">{{ 'eys.title' | translate }}</span>
	<span id="application-name" *ngIf="showCompnayName" class="uppercase application-name is-not-landing-page">{{ 'eys.title' | translate }}</span>

	<span id="customerCompany" *ngIf="showCompnayName" class="company-name sdds-detail-01 uppercase">{{ companyName }}</span>
	<span class="scania-symbol"></span>
</div>

<div *ngIf="showCompnayName" class="sdds-content sdds-background-grey-50">
	<app-main (newItemEvent)="addCompanyName($event)"></app-main>			
</div>