<div class="sdds-container padding">
    <div class="sdds-row">   
        <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8">
            <p class="sdds-headline-03 uppercase text-center sdds-text-blue-900" data-cy="eys-slogan">{{ 'eys.slogan' | translate }}</p>
        </div>
        <div class="sdds-col-xxlg-16 sdds-col-xlg-16 sdds-col-lg-16 sdds-col-md-8 sdds-col-sm-8 text-center">
            <p id="salesRepInfo" class="sdds-detail-01 sdds-text-grey-600">
                {{ 'eys.yourSalesRep' | translate: {salesRepFullName: main.SalesRepFullName} }}               
            </p>
            <a id="scButtonId" target="_blank" href="{{ main.ScUrl }}" data-analytics="{'eventComponent':'explore your scania','eventType':'button','eventLabel':'go to scania configurator'}">
                <button class="sdds-btn sdds-btn-primary uppercase margin-top margin-bottom" id="scButton">
                    {{ 'eys.scBtn' | translate }}
                </button>
            </a>
        </div>
    </div>
</div>