import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { CabComponent } from './modules/cab/cab.component';
import { MainComponent } from './core/main/main.component';
import { MainService } from './core/main/main.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CookieComponent } from './core/cookie/cookie.component';
import { ViewYourTruckComponent } from './modules/view-your-truck/view-your-truck.component';
import { ErrorComponent } from './core/error/error.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BannerComponent } from './core/banner/banner.component';
import { CampaignComponent } from './modules/campaign/campaign.component';
import { StuartComponent } from './modules/stuart/stuart.component';
import { QuotationComponent } from './modules/quotation/quotation.component';
import { DocumentsComponent } from './modules/documents/documents.component';
import { MopinionComponent } from './core/mopinion/mopinion.component';
import { TranslationService } from './services/translation.service';
import { MarketingMaterialComponent } from './modules/marketing-material/marketing-material/marketing-material.component';


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        CabComponent,
        MainComponent,
        CookieComponent,
        ViewYourTruckComponent,
        ErrorComponent,
        BannerComponent,
        CampaignComponent,
        StuartComponent,
        QuotationComponent,
        DocumentsComponent,
        MopinionComponent,
        MarketingMaterialComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
               // useFactory: HttpLoaderFactory,
                useClass: TranslationService,
                deps: [HttpClient]
            }
        })
    ],
    providers: [MainService, MainComponent, ErrorComponent, CookieComponent],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA] // Add the Schema
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
