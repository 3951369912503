import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public companyName: string;
    public showCompnayName = false;
    constructor(private translate: TranslateService) { }

    ngOnInit(): void {

        //set translation language
        if (sessionStorage.getItem('translationLanguage')) {
            this.translate.use(sessionStorage.getItem('translationLanguage'));
        }

        // Check if default page
        const pageUrl = window.location.pathname;
        if (pageUrl.toString() == '/') {
            this.showCompnayName = false;
        } else {
            this.showCompnayName = true;
        }
    }

    addCompanyName(companyName) {
        this.companyName = companyName;
    }


}
