import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CabDetail, EYSModule, Main } from './main';
import { MainService } from './main.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    @Output() newItemEvent = new EventEmitter<string>();

    public displayCab = false;
    public displayViewYourTruck = false;
    public sectionTopRight = '';
    public sectionBottom = '';
    public showErrorMessage = false;
    public eysPageExpiredMessage = false;
    public showCookieMessage = false;
    public companyName: string;
    public cabDetails: CabDetail;
    public translationLanguage: string;
    public main: Main;
    public token: string;
    public displayQuotationModule = false;
    public modules: EYSModule[];
    public firstModuleSlot = false;
    public secondModuleSlot = false;
    public thirdModuleSlot = false;
    private cabImageUrls: string[];
    public displayAdditionalDocs = false;
    public displaySpinner = true;

    constructor(private mainService: MainService, private translate: TranslateService) { }

    ngOnInit(): void {
        const params = new URLSearchParams(document.location.search);
        this.token = params.get("token");
        this.getSpecification();
    }

    // Get specification content
    getSpecification(): void {
        let pagePathId;
        const pageUrl = window.location.pathname;
        const preview = 'preview';

        //Check for preview page
        if (pageUrl.toString().includes(preview)) {
            pagePathId = pageUrl.slice(pageUrl.indexOf(preview) + (preview.length + 1));
            this.showCookieMessage = false;
        } else {
            pagePathId = pageUrl.substring(1);
            this.showCookieMessage = true;
        }
        // Calling service after checking specificationId available in the path
        if (pagePathId && pagePathId != null) {
            this.mainService.getSpecificationDetails(pagePathId, this.token).subscribe(
                (main: Main) => {
                    if (main.message == 'SpecificationId not found in the table') {
                        this.showErrorMessage = true;
                        this.displaySpinner = false;
                        return;
                    }
                    
                    if (main.message === 'Malformed token' || main.message === 'Invalid token') {
                        this.eysPageExpiredMessage = true;
                        this.displaySpinner = false;
                        return;
                    }

                    this.addCompanyName(main.CompanyName);
                    
                    //set translation language in session variable
                    this.translationLanguage = main.FallbackLanguage;
                    if (this.translationLanguage) {
                        sessionStorage.setItem('translationLanguage', this.translationLanguage)
                        this.translate.use(sessionStorage.getItem('translationLanguage'));
                        this.displaySpinner = false;
                    }
                    else {
                        sessionStorage.setItem('translationLanguage', 'en-GB');
                        this.translate.use(sessionStorage.getItem('translationLanguage'));
                        this.displaySpinner = false;
                    }

                    if (main.CabImageUrls && main.CabImageUrls.length > 0) {
                        this.displayCab = true;
                        this.cabImageUrls = main.CabImageUrls;
                        this.cabDetails = main.CabDetails;
                    }
                    this.main = main;
                    if (main.ScUrl) {
                        this.displayViewYourTruck = true;
                    }
                    const sectionBottomModule = main.Modules?.find(m => m.Position === 'bottom' && m.Show === true);
                    if (sectionBottomModule) this.sectionBottom = sectionBottomModule.Key;        
                    if (!main.Modules) this.sectionBottom = 'cab';
                    this.modules = this.main.Modules;
                    const firstOrderModule = this.modules?.find( m => m.Order === 1 && m.Show === true ); 
                    if (firstOrderModule) this.firstModuleSlot = true; 
                    const secondOrderModule = this.modules?.find( m => m.Order === 2 && m.Show === true ); 
                    if (secondOrderModule) this.secondModuleSlot = true;                    
                    const thirdOrderModule = this.modules?.find(m => m.Order === 3 && m.Show === true);
                    if (thirdOrderModule && this.main.CountryIso === 'PL') this.thirdModuleSlot = true;
                    if (((main.QuotationDocs && main.QuotationDocs.length) || main.QuotationDocs === null) && main.QuotationValidity) {
                        this.displayQuotationModule = true;
                    }
                    if (main.AdditionalDocs && main.AdditionalDocs.length)  {
                        this.displayAdditionalDocs = true;
                    }
                }
            );
        }
    }

    // Pass company name through event emitter
    addCompanyName(value: string) {
        this.newItemEvent.emit(value);
    }

    // Return list of cab images
    getCabImageUrls(): string[] {
        return this.cabImageUrls;
    }
}
