import { Component, Input, OnInit } from '@angular/core';
import { CabDetail } from 'src/app/core/main/main';
import { MainComponent } from 'src/app/core/main/main.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cab',
    templateUrl: './cab.component.html',
    styleUrls: ['./cab.component.scss']
})
export class CabComponent implements OnInit {
    @Input() cabDetails: CabDetail;

    public imgUrls: string[];
    public cabInSpecUrl: string[] = [];
    public cabToCompareUrl: string[] = [];
    public viewCabModel = false;

    constructor(private mainComponent: MainComponent, private translate: TranslateService) { }

    ngOnInit(): void {
        //set translation language
        if (sessionStorage.getItem('translationLanguage')) {
            this.translate.use(sessionStorage.getItem('translationLanguage'));
        }

        this.imgUrls = this.mainComponent.getCabImageUrls();
        this.connectCabImageWithName();
    }

    public connectCabImageWithName(): void {
        if (this.cabDetails && this.cabDetails.CabInSpecs) {
            this.imgUrls.forEach(imgUrl => {
                if (imgUrl.includes(this.cabDetails.CabInSpecs) && imgUrl.includes('SV.png')) {
                    this.cabInSpecUrl.push(imgUrl);
                }
                if (imgUrl.includes(this.cabDetails.CabInSpecs) && imgUrl.includes('TV.png')) {
                    this.cabInSpecUrl.push(imgUrl);
                }
            });
        }

        if (this.cabDetails && this.cabDetails.CabToCompare) {
            this.imgUrls.forEach(imgUrl => {
                if (imgUrl.includes(this.cabDetails.CabToCompare) && imgUrl.includes('SV.png')) {
                    this.cabToCompareUrl.push(imgUrl);
                }
                if (imgUrl.includes(this.cabDetails.CabToCompare) && imgUrl.includes('TV.png')) {
                    this.cabToCompareUrl.push(imgUrl);
                }
            });
        }
    }

    public openModel(): void{
        this.viewCabModel = true;
    }

    public closeModel(): void{
        this.viewCabModel = false;
    }
}
