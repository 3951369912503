<div class="sdds-footer">
    <div *ngIf="!displayLandingPage" class="sdds-footer-main footer-top no-padding">
        <div class="sdds-container-fluid no-padding">
            <div class="sale-rep-contact">
                <div class="contact-info">
                    <div class="contact-details">
                        <ul>
                            <li>
                                <h3 data-cy="contact-header" class="sdds-headline-04 contact-header">
                                    {{'eys.saleRepContactInformation' | translate}}</h3>
                            </li>
                            <li data-cy="sale-rep-name">
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                                    xml:space="preserve">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M16 3.98a6.642 6.642 0 1 0 0 13.283 6.642 6.642 0 0 0 0-13.284ZM7.358 10.62a8.642 8.642 0 1 1 17.284 0 8.642 8.642 0 0 1-17.284 0ZM1.994 28.387a7.4 7.4 0 0 1 7.4-7.4h13.212a7.4 7.4 0 0 1 7.4 7.4v.625a1 1 0 1 1-2 0v-.625a5.4 5.4 0 0 0-5.4-5.4H9.394a5.4 5.4 0 0 0-5.4 5.4v.625a1 1 0 1 1-2 0v-.625Z"
                                        fill="currentColor" />
                                </svg>
                                {{saleRepName}}
                            </li>
                            <li data-cy="sale-rep-email">
                                <a class="sdds-link sdds-link--no-underline" href="mailto:{{saleRepEmail}}"
                                    data-analytics="{'eventComponent':'sale representative contact-info','eventType':'link','eventLabel':'email sale representative'}">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M2 8C2 6.89543 2.89543 6 4 6H28C29.1046 6 30 6.89543 30 8V24C30 25.1046 29.1046 26 28 26H4C2.89543 26 2 25.1046 2 24V8ZM27.0007 8H4.99926L16 14.3543L27.0007 8ZM4 9.73248V24H28V9.73248L17.0004 16.0862C16.3814 16.4437 15.6186 16.4437 14.9996 16.0862L4 9.73248Z"
                                            fill="white" />
                                    </svg>
                                    {{saleRepEmail}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="contact-image">
                        <img alt="salerep-contact-info" src="{{contactImageUrl}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sdds-footer-main" [ngClass]="{'no-padding': !showPrivacySettings}">
        <ul class="sdds-footer-main-links" *ngIf="showPrivacySettings">
            <li><a class="sdds-link uppercase privacy-text" (click)="openCookieModal()"
                    data-cy="privacy-text">{{'eys.cookiePrivacySettingsBtn' |
                    translate}} </a></li>
        </ul>
        <div class="sdds-footer-main-brand copywrite-text" data-cy="copyright-text">
            <p>Copyright &copy; {{currentYear}} Scania All rights reserved. {{additionalFooterText}}</p>
        </div>
    </div>
</div>