import { Component, Input, OnInit } from '@angular/core';
import { Main } from 'src/app/core/main/main';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-view-your-truck',
    templateUrl: './view-your-truck.component.html',
    styleUrls: ['./view-your-truck.component.scss']
})
export class ViewYourTruckComponent implements OnInit {
  @Input() main: Main;
  
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
      //set translation language
      if (sessionStorage.getItem('translationLanguage')) {
          this.translate.use(sessionStorage.getItem('translationLanguage'));
      }
  }
}
