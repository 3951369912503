import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { AppSettings } from '../core/appSettings';

@Injectable({
  providedIn: 'root'
})
export class TranslationService implements TranslateLoader {

  constructor(private http: HttpClient) { }
  getTranslation(lang: string): Observable<any> {
    if (lang) {
      const url = `${AppSettings.TRANSLATION_SERVICE_URL}/eys/${lang}`;
      const response = this.http.get(url);
      return response;
    }
    else {
      const url = `${AppSettings.TRANSLATION_SERVICE_URL}/eys/en-GB`;
      const response = this.http.get(url);
      return response;
    }
  }
}
