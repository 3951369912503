import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    constructor(private translate: TranslateService) { }

    ngOnInit(): void {
        //set translation language
        if(sessionStorage.getItem('translationLanguage')){
            this.translate.use(sessionStorage.getItem('translationLanguage'));
        }
    }
}
